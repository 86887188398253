/** @jsx jsx */
import { jsx, Heading, Link as TLink } from "theme-ui";
import { useStaticQuery, graphql, Link } from "gatsby";
import TwoColFlex from "../Util/TwoColFlex";

const About = () => {
  const spotifyQuery = useStaticQuery(graphql`
    query spotifyQuery {
      allSpotifyTopTrack(limit: 10) {
        edges {
          node {
            image {
              localFile {
                childImageSharp {
                  id
                }
              }
            }
            id
            href
            external_urls {
              spotify
            }
            preview_url
            spotifyId
            name
          }
        }
        distinct(field: href)
      }
    }
  `);

  const imgSx = { maxWidth: 200, width: `75%` };
  const codeImg = <img src="/assets/E1C1.svg" alt="code_img" sx={imgSx} />;
  const codeSection = (
    <div sx={{ fontSize: [1, 2] }}>
      <h2 sx={{ color: `primary` }}> Developer </h2>
      <p>
        I graduated from UC Berkeley in 2018 with a BS in Bioengineering and a
        minor in Electrical Engineering and Computer Sciences. After working in
        research for ~2 years, I realized how useful and how much I enjoyed the
        coding. In developing software for research and personal projects, I
        delved into software engineering and pursued it as a career.
      </p>
    </div>
  );
  const natureImg = (
    <img src="/assets/1F9D7-1F3FB.svg" alt="nature_img" sx={imgSx} />
  );
  const natureSection = (
    <div sx={{ fontSize: [1, 2] }}>
      <h2 sx={{ color: `primary` }}> Adventures </h2>
      <p>
        I enjoy backpacking, fishing, and climbing.
      </p>
    </div>
  );
  const musicImg = <img src="/assets/1F3A7.svg" alt="music_img" sx={imgSx} />;

  const spotifyTracks = spotifyQuery.allSpotifyTopTrack.distinct.map((e) => {
    const splitHref = e.split("tracks/");
    return `https://open.spotify.com/embed/track/${splitHref[1]}`;
  });

  const musicSection = (
    <div sx={{ fontSize: [1, 2] }}>
      <h2 sx={{ color: `primary` }}> What&apos;s on repeat... </h2>

      <div
        sx={{
          overflowY: `hidden`,
          touchAction: `pan-y`,
          width: "100%",
          display: "flex",
          padding: `calc(0.25 * 0.5 * 80px) calc(0.25 * 0.5 * 300px)`,
        }}
      >
        {spotifyTracks.map((i) => (
          <iframe
            src={i}
            width="300"
            height="80"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title="spotify-test"
            sx={{
              transition: `transform 0.3s ease`,
              ":hover": {
                transform: `scale(1.25)`,
                zIndex: 1,
              },
            }}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="about">
      <Heading variant="styles.h2"> ... a little about myself </Heading>
      <TwoColFlex blurb={codeImg} content={codeSection} />
      <div sx={{ variant: `dividers.bottom` }} />
      <TwoColFlex blurb={natureImg} content={natureSection} reverse={1} />
      <div sx={{ variant: `dividers.bottom` }} />
      <TwoColFlex blurb={musicImg} content={musicSection} />
    </div>
  );
};

export default About;
